@import url('https://fonts.googleapis.com/css?family=Lato');

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  font-weight: normal;
  /* -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: rgb(0, 0, 0); */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* bootstrap layout column padding */
.col {
	padding: 0px;
}

/* no focus outline */
.btn.focus {
    outline: none !important;
	outline-offset: none !important;
	box-shadow: none;
}

/* "yellow":"#f0e442", */

/* "orange":"#e69f00",
"sky_blue":"#56b4e9",
"bluish_green":"#009e73",
 "yellow":"#e3e300",
"blue":"#0072b2",
"vermillion":"#d55e00",
"pink":"#cc79a7" */